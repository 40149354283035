<template>
  <div class="NotFound py-20">
    <div class="constrain">
      <div class="row-12">
        <div class="col-12">

          <h1 class="text-center">
            Invaders destroyed this page!
          </h1>
          <div class="mt-12 flex justify-center">
            <canvas id="space-invaders" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

</style>
